import * as React from "react";
import useImage from "use-image";
import {  Image } from 'react-konva';
import {useMemo} from "react";

type Props={
    x:number,
    y:number,
    level:number,
    textNumber:number,
    lng:string,
    onClick:()=>void
}

export const HelpImage=(props:Props)=>{
    const link=useMemo(()=>{
        let lngPostFix:string="";
        switch (props.lng){
            case 'en':
            case 'en-EN':
            case 'en-US':
                lngPostFix="_en";
                break;
            case 'de-DE':
            case 'de':
                lngPostFix="_de";
                break;
            case 'ru-RU':
            case 'ru':
                lngPostFix="";
                break;
        }
        return process.env.PUBLIC_URL+'/assets/help/'+props.level+'_'+props.textNumber+lngPostFix+'.svg';
    },[props]);

    const [image] = useImage(link);

    return <Image

        onClick={()=>{props.onClick()}}
        onTouchEnd={()=>{props.onClick()}}
        image={image}  x={props.x} y={props.y} />;
}