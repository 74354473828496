import * as React from "react";
import {useTranslation} from "react-i18next";
import {createUseStyles} from "react-jss";


const useStyles=createUseStyles({
    rules:{
        whiteSpace: "pre-wrap",
        padding:"20px 32px" ,
        background:"#fff",
        borderBottom:"1px solid #e1e1e1"
    }
});

export const Rules= ()=>{

    const {t,i18n}=useTranslation();
    const classes= useStyles();
    // console.log("i18n.language",i18n.language);

    if(i18n.language.match(/.*?ru.*?/ig))return <div className={classes.rules}><Ru /></div>;
    if(i18n.language.match(/.*?en.*?/ig))return <div className={classes.rules}><En /></div>;
    if(i18n.language.match(/.*?de.*?/ig))return <div className={classes.rules}><De /></div>;

    return <div className={classes.rules}><Ru /></div>;
}



const En=()=>(
    <div>
        How to play the game "Don't be negative":
        <br/><br/>
        The screen displays a geometric figure that consists of vertices connected by straight lines. Inside each vertex there is a positive or negative number. The player's task is to redistribute the numbers between the vertices so that no vertex has negative values for the minimum number of moves.
        <br/><br/>
        <b>Choice of moves</b>
        <br/><br/>
        Add one unit to the number in a vertex from all vertices connected with it by lines.
        <br/><br/>
        Subtract one from the number in the vertex in favor of all vertices connected with it by lines.
        <br/><br/>
        How to do it: if a vertex has several links, the player transfers one unit from it to all the lines that come from it. For example, if a vertex with five links has number "+3", after the move it will have number "-2". To solve the problem with the minimum number of steps, the player must correctly calculate the number of ties and understand how the numbers in the vertices change after the step.

    </div>
)

const Ru=()=>(
    <div>
        Как играть в игру «Don't be negative»:
        <br/><br/>
        На экране отображается геометрическая фигура, которая состоит из вершин, связанных прямыми линиями. Внутри каждой вершины указано положительное или отрицательное число. Задача игрока: за минимальное количество ходов перераспределить числа между вершинами так, чтобы ни в одной вершине не осталось отрицательных значений.
        <br/><br/>
        Варианты ходов
        <br/><br/>
        Прибавить к числу в вершине по единице из всех вершин, связанных с ней линиями.
        <br/><br/>
        Из числа в вершине вычесть по единице в пользу всех вершин, связанных с ней линиями.
        <br/><br/>
        Как это сделать: если у вершины несколько связей, игрок переводит из неё по единице сразу по всем линиям, которые из неё исходят. Например, если внутри вершины с пятью связями стоит число «+3», после хода в ней останется число «-2». Чтобы решить задачу минимальным количеством шагов, игроку нужно правильно рассчитать количество связей и понять, как изменяются числа в вершинах после шага.

    </div>
)

const De=()=>(
    <div>
        Wie man das Spiel "Don’t be negativ" spielt:
        <br/><br/>
        Auf dem Bildschirm wird eine geometrische Figur angezeigt, die aus Eckpunkten besteht, die durch gerade Linien verbunden sind. In jedem Scheitelpunkt steht eine positive oder negative Zahl. Die Aufgabe des Spielers ist, die Zahlen zwischen den Eckpunkten so zu verteilen, dass kein Eckpunkt negative Werte für die minimale Anzahl von Zügen hat.
        <br/><br/>
        Auswahl der Züge
        <br/><br/>
        Addiere eine Einheit zu der Zahl in einem Scheitelpunkt von allen durch Linien verbundenen Scheitelpunkten.
        <br/><br/>
        Ziehe von der Zahl des Scheitelpunkts eine Eins ab, und zwar zugunsten aller Scheitelpunkte, die mit ihm durch Linien verbunden sind.
        <br/><br/>
        So wird's gemacht: Wenn ein Scheitelpunkt mehrere Verbindungen hat, überträgt der Spieler eine Einheit von ihm auf alle Linien, die von ihm ausgehen. Wenn z. B. ein Knotenpunkt mit fünf Gliedern die Nummer "+3" hat, wird er nach der Verschiebung die Nummer "-2" haben. Um das Problem mit der minimalen Anzahl von Schritten zu lösen, muss der Spieler die Anzahl der Verbindungen richtig berechnen und verstehen, wie sich die Zahlen in den Scheitelpunkten nach dem Schritt ändern.

    </div>
)
