import * as React from "react";
import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";

const useStyles=createUseStyles({
    "@global":{
        "#root":{
            height:"calc(100vh + 154px)",
            // position:"relative",
            // minWidth:"100wh",z
        },
    },

    footer:{

        "& a":{
            color:"#2F62DB",
            textDecoration:"none",
            borderBottom:"0.5px solid #2F62DB",
            "&:hover":{
                borderBottom:" 0px none"
            }
        },
        width:"100%",
        height:154,
        position:"relative",
        left:"0px",
        bottom:"0px",
        "&.ingame":{
            bottom:"-200px",
        },
        background:"#fff",
        "& .logo":{
            position:"absolute",
            left:"32px",
            top:"30px",

            fontFamily:"Montserrat",
            fontStyle:"normal",
            fontWeight:"bold",
            fontSize:"48px",
            lineHeight:"95%",

        },
        "& .text1":{
            position:"absolute",

            left:"32px",
            top:"24px",
            fontFamily:"Montserrat",
            fontStyle:"normal",
            fontWeight:"normal",
            fontSize:"14px",
            lineHeight:"130%",
        },
        "& .text2":{
            top:"24px",
            position:"absolute",


            left:"332px",
            fontFamily:"Montserrat",
            fontStyle:"normal",
            fontWeight:"normal",
            fontSize:"14px",
            lineHeight:"130%",
        },
        "& .text3":{
            top:"30px",
            position:"absolute",

            right:"65px",
            fontFamily:"Montserrat",
            fontStyle:"normal",
            fontWeight:"normal",
            fontSize:"14px",
            lineHeight:"160%",
        },
        "& .text4":{
            top:"24px",
            position:"absolute",

            right:"32px",
            fontFamily:"Montserrat",
            fontStyle:"normal",
            fontWeight:"normal",
            fontSize:"14px",
            lineHeight:"150%",
            textAlign:"right",
        },

        "& .textCookie":{
            fontFamily:"'Montserrat', sansSerif",
            fontStyle:"normal",
            fontWeight:"normal",
            fontSize:"12px",
            lineHeight:"130%",
            left:"0px",
            top:"106px",
            position:"absolute",
            width:'calc(~"100% - 64px")',
        borderTop:"1px solid #8a8a8a",
        opacity:"0.54",
        margin:"0px 32px",
        paddingTop:"12px",
        textAlign:"left",
    }

}

});

export const DesktopFooter:React.FC=()=>{
    let ingame=false;
    const classes=useStyles();

    const {t}=useTranslation();
    return (
        <div className= {(ingame?"ingame ":'')+classes.footer}>
            <div className="social">
                <div className="fb"></div>
                <div className="tg"></div>
                <div className="ok"></div>
                <div className="tw"></div>
                <div className="vk"></div>
            </div>

            <div className="text1">
                {t('Руководитель')}: <a target={"_blank"}  rel={'nofollow'} href="https://www.facebook.com/dunaich">{t('Константин Дунаев')}</a><br/>
                {t('Арт-директор')}: <a  target={"_blank"} rel={'nofollow'}  href="https://www.facebook.com/sinelnikov.ilya">{t('Илья Синельников')}</a><br/>
                {t('Тестировщик')}: <a  target={"_blank"} rel={'nofollow'}  href="https://www.facebook.com/a.n.kozelskikh">{t('Козельских Александр')}</a><br/>
                {/*{t('Шеф')}: {t('Николай Товеровский')}<br/>*/}

            </div>

            <div className="text2">
                {t('Разработчик')}: <a target={"_blank"} rel={'nofollow'}  href="https://www.facebook.com/valentin.shanyazov">{t('Валентин Шаньязов')}</a><br/>
                {t('Дизайнер')}: <a  target={"_blank"}  rel={'nofollow'}  href="https://www.facebook.com/elenashrv">{t('Елена Ширяева')}</a><br/>
                {t('Редактор')}: <a  target={"_blank"} rel={'nofollow'}   href="https://www.facebook.com/yanayudinav">{t('Яна Юдина')}</a>
            </div>

            <div className="text4">
                {/*Почта для обратной связи */}
                <span className={"br_in_mob"}/><a href="mailto:info@dontbenegative.app">info@dontbenegative.app</a><br/>
                <span className={"br_in_mob"} style={{lineHeight:"80%"}} ><br/></span>
                {t('Сделано в 2019 году')} <span className={"br_in_mob"}/> <a href="https://bureau.ru/school/">{t('в Школе Бюро Горбунова')}</a>
            </div>
            <div className="textCookie">
                {t('cookiesText')} </div>
        </div>

    );
};
