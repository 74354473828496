import * as React from "react";

import { useContext} from "react";
import Konva from "konva";
import {Circle, Text, Image, Layer, Path} from "react-konva";
// import {Dot} from "../../assets/dots_rem";

//@ts-ignore
// import dot0 from "/src/Components/assets/images/Oval.svg";
//@ts-ignore
// import dot1 from "/src/Components/assets/images/Oval-1.svg";


const radius=30;
const fontSize=25;
// type PointProps=
//     ReturnType<typeof parseLevelData>["points"][0]
//     & {pointRef:(t:any)=>void , color:(string & { __validColorString: true }) | "none"}

type PointProps={
    x:number,
    y:number,
    value:number,
    color:string,
    index:number,
    onDblClick :(n:number)=>void,
    onMouseDown :(n:number)=>void,
    onMouseUp :(n:number)=>void,
    onTouchStart :(n:number)=>void,
    onTouchCansel :(n:number)=>void,
    onTouchEnd :(n:number)=>void,
}

  const Point=(props:PointProps)=>{
    const fixR=props.value>=0?0:2;
    return (
        <React.Fragment>
          {/*<Path*/}
          {/*          x={props.x-radius}*/}
          {/*          y={props.y-radius}*/}
          {/*          fill={"#f10a20"} fill-rule="evenodd" clip-rule="evenodd" data="M-0.000175476 29.4961C-0.000175476 45.4961 14.0373 58.7778 35.4999 59.9961C56.9624 61.2144 58.0515 32.7397 55.0001 23.5326C51.9488 14.3255 42.4999 -6.50387 29.3172 1.99611C23.8628 5.51306 14.6131 6.58841 9.49986 9.53262C2.25512 13.7042 -0.000175476 20.1162 -0.000175476 29.4961Z" />*/}


            {/*<Dot*/}
            {/*    //@ts-ignore*/}
            {/*    color={"#dfd431"} />*/}
            <Circle
               // ref={(r)=>props.pointRef(r)}
               x={props.x}
               y={props.y}
               radius={radius-fixR}
               // fill={props.color}
                stroke={'#000'}
                strokeWidth={fixR}
                // onClick={this.actionIn.bind(this,index)}
                {...(props.value>=0?{fill:props.color}:{fill:"#fff"})}
            />

           {/* <Text
                text={props.value as unknown as string}
                  align={'center'}
                  fontFamily={'Montserrat'}
                  verticalAlign={'middle'}
                  // fontSize={this.state.fontSize}
                  // fill={this.props.color}
                  // width={this.state.size*2}
                  // height={this.state.size*2}
                  x={props.x-radius}
                  y={props.y-radius}
                  //
                  // onDblClick={() => {this.props.actions.onDblClick(this.state.index);}}
                  // onMouseDown={()=>{this.props.actions.onMouseDown(this.state.index);}}
                  // onMouseUp={()=>{this.props.actions.onMouseUp(this.state.index);}}
                  // onTouchStart={()=>{this.props.actions.onTouchStart(this.state.index);}}
                  // onTouchCansel={()=>{this.props.actions.onTouchCansel(this.state.index);}}
                  // onTouchEnd={()=>{this.props.actions.onTouchEnd(this.state.index);}}
            />*/}
            <Text text={(props.value>0?"+":'')+String(props.value) }
                  align={'center'}
                  verticalAlign={'middle'}
                  fontFamily={'Montserrat'}
                  fontSize={fontSize}
                  fontStyle={"normal"}
                // fill={"#fff"}

                  fill={"#000"}
                  width={radius*2}
                  height={radius*2}
                  x={props.x-radius}
                  y={props.y-radius}

                  onDblClick={() => {props.onDblClick(props.index);}}
                  onMouseDown={()=>{props.onMouseDown(props.index)}}
                  onMouseUp={()=>{props.onMouseUp(props.index)}}
                  onTouchStart={()=>{props.onTouchStart(props.index)}}
                  onTouchEnd={()=>{props.onTouchEnd(props.index)}}
                  onTouchCansel={()=>{props.onTouchCansel(props.index)}}

            />

        </React.Fragment>
    );
}

export default Point;