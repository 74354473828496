import * as React from "react";
import { LevelResultType, LevelType} from "./Types";

import {useLevelsData} from "../hooks/useLevelsData";
import {useEffect} from "react";


type GameContextType={
    currentLevel:number,
    openedLevel:number,
    results:LevelResultType[],
    revertMoveAvailable:number,
    setRevertMoves:(n:number)=>void
    levelsData:LevelType[] ,
    addUserResult:(result:LevelResultType)=>void,
    lastGameResult:LevelResultType | null
    setLastGameResult:(r:LevelResultType | null)=>void
}



export const GameContext=React.createContext<GameContextType | null>(null);

function findOpenedLevel(results:LevelResultType[]):number{
    try{


        let sorted=results.sort((a,b)=>a.level>b.level?1:-1);
        let maxLevel=0;
        for(let item of sorted){
            if(item.best>=item.moves){
                maxLevel=item.level;
            }
        }

        return maxLevel+1;
    }catch(e){
        localStorage.setItem("results",JSON.stringify([]));
        document.location.reload()
    }
    return  1;
}


export const withGameContext = <P extends {  }>(Component: React.ComponentType<P>) => (props: React.ComponentProps<typeof Component>) => {

    const levelsData=useLevelsData();

    const [results,setResults]=React.useState< GameContextType['results']>(JSON.parse(localStorage.getItem('results') || JSON.stringify([])) as LevelResultType[]);

    const [revertMoves,setRevertMoves]=React.useState< GameContextType['revertMoveAvailable']>(parseInt(localStorage.getItem('revertMoveCount') || "3"));

    const [openedLevel,setOpenedLevel]=React.useState(findOpenedLevel(results));
    const [lastGameResult,setLastGameResult]=React.useState<LevelResultType | null>(null);

    // useEffect(()=>{
    //     let ind:number=results.findIndex(f=>!f);
    //          // while (ind=results.findIndex(f=>!f.level)){
    //              let newResults=[...results];
    //    if(ind>-1) {
    //        newResults.splice(ind, 1);
    //        localStorage.setItem("results",JSON.stringify(newResults));
    //        setResults(newResults);
    //    }
    //          // }
    // },[results]);

    const updateRevertMoves=(n:number)=>{
        setRevertMoves(n);
        localStorage.setItem("revertMoveCount",String(n));
    }
    const addUserResult: GameContextType['addUserResult']=(result)=>{
        let currentResults=[...results];
        //смотрим есть ли уже результат
        let index=currentResults.findIndex(f=>f.level===result.level);
        //если результат существует
        if(index>-1){
            //Если существующий хуже нового.

            if(currentResults[index].moves>=result.moves){
                currentResults[index]=result;
            }
        }else { //Если ещё не было результатов запихиваем.
            currentResults.push(result);
        }
        setResults(currentResults);
        setOpenedLevel(findOpenedLevel(currentResults));
        localStorage.setItem("results",JSON.stringify(currentResults));
    }
    const context:GameContextType={
        levelsData,
        results,
        openedLevel,
        addUserResult,
        lastGameResult,
        setLastGameResult:setLastGameResult,
        currentLevel:0,
        revertMoveAvailable:revertMoves,
        setRevertMoves:updateRevertMoves
    };

    return (
        <GameContext.Provider value={context}  >
            {!!levelsData.length &&  <Component {...props} />}

        </GameContext.Provider>
    );
}