import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect} from "react";
import {GameContext} from "../lib/GameContext";
import {useWindowSize} from "../hooks/useWindowSize";


export const  WhereToGo:React.FC<{}>=( )=>{
    const params=useParams<{level?:string}>();
    const gameContext=useContext(GameContext)!;
    const navigate=useNavigate();


    useEffect(()=>{

        if(!params.level){
            navigate("/level"+gameContext.openedLevel);
        }else
        if(gameContext.openedLevel>gameContext.levelsData.length) {
            navigate("/list")
        } else
        if(gameContext.openedLevel<Number(params?.level)){
            navigate("/level"+gameContext.openedLevel)
        }

        },[]);


    return <></>

}