import * as React from 'react'; 
import {Circle, Image, Layer, Path, Stage, Text} from "react-konva";
import {createUseStyles} from "react-jss";
import {useWindowSize} from "../hooks/useWindowSize";
import Point from './konva/Point';
import {LangSteps, parseLevelData} from "../lib/parseLevelData";
import {useContext, useEffect, useMemo, useState} from "react";
import {GameContext} from "../lib/GameContext";
import LineAnimation from "./konva/LineAnimation";
import {useParams} from "react-router-dom";
import {DrawLine} from "./konva/Line";
import {LevelResultType, LevelType} from "../lib/Types";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {RestartButton} from "./RestartButton";
import {RevertMove} from "./RevertMove";
import {SelectLevelButton} from "./SelectLevelButton";
import {OnGameAd} from "./Ad";
import {HelpImage} from "./konva/HelpImage";
import {DesktopFooter} from "./DesktopFooter";
import {Helmet} from "react-helmet";



type Props={
    level:number,
}

type UseStylesProps={
    width:number
}

const useStyles=createUseStyles<string,UseStylesProps>({
    mainWindow:{
        fontFamily:"'Montserrat', monospace",
        // width:"100vw",
        // height:"100vh",
        height:"100%",
        maxHeight: "-webkit-fill-available !important",
        overflow:"hidden",
        position:"relative",
        left:0,
        top:0,
    },
    canvas:{
        width:({width}:UseStylesProps)=>{
            return width;
        },
        margin:"auto",
        // position:"absolute",
        // left:0,
        // top:0,
    },
    lessonsGoNext:{
        width:"190px",
        marginLeft:"-95px",
        left:"50%",
        position:"absolute",
        bottom:"37px",
        top:"auto",
        marginTop:"185px",
        background:"#558AF6",
        lineHeight:"48px",
        border:"0px ",
        height:"48px",
        padding:"0px",
        textAlign:"center",
        color:"#fff",
        cursor:"pointer",
        borderRadius:"24px",
        outline:"0px",
        fontStyle:"normal",
        fontWeight:"bold",
        fontSize:"20px",
        "@media  only screen and (min-width:319px) and (max-width: 997px)":{
            bottom:18,
        }
    },
    gameLevelInfo:{

        textTransform:"capitalize",
        fontFamily:"Montserrat,sans-serif",
        fontStyle:"normal",
        fontWeight:"bold",
        fontSize:"10px",
        marginLeft:"-75px ",
        top:"31px",
        width:"150px",
        textAlign:"center",
        left:"50%",
        position:"absolute",
        "@media  only screen and (min-width: 997px)":{

            top:"57px",
            marginLeft:"-150px",
            width:"300px",
            fontWeight:"700",
            fontSize:"40px",
            lineHeight:"49px",
        },
        "@media  only screen and (min-width:715px) and (max-width: 997px)":{
            fontSize:"20px",
            top:50,
        },
        "@media  only screen and (min-width:279px) and (max-width: 715px)":{

            width:"150px",
            fontStyle:"normal",
            // fontSize:"10px",
            lineHeight:"12px",
            fontSize:20,
            top:65,
        }

},


   gameMoveCountInfo:{
        position:"absolute",
       fontStyle:"normal",
       fontWeight:"700",
       fontSize:"32px",
       lineHeight:"39px",
       bottom:"52px",
       left:"50%",
       marginLeft:"-120px",
    width:"240px",
       textAlign:"center",
       textTransform:"capitalize",
       "@media  only screen and (min-width:279px) and (max-width: 715px)":{
           position:"absolute",
           bottom:"32px",
           left:"50%",
           marginLeft:"-75px",
           fontFamily:"Montserrat,monospace",
           fontStyle:"normal",
           fontWeight:"700",
           fontSize:"24px",
           lineHeight:"29px",
           width:"150px",
           textAlign:"center",
       }

},
    gameLevelBestInfo:{

        textTransform:"capitalize",
        position:"absolute",
        fontFamily:"Montserrat,monospace",
        fontStyle:"normal",
        fontWeight:"400",
        "@media  only screen and (min-width: 997px)":{

            fontSize:"16px",
            lineHeight:"20px",
            left:"50%",
            bottom:"28px",
            width:300,
            marginLeft:5,
        },

        "@media  only screen and (min-width:279px) and (max-width: 715px)":{

            bottom:"18px",
            left:"50%",
            fontSize:"10px",
            lineHeight:"12px",
            width:"150px",
            marginLeft:"5px",
            textAlign:"left",
        }

    },
    gameLevelTargetInfo:{

        textTransform:"capitalize",
        position:"absolute",
        textAlign:"right",
        "@media  only screen and (min-width: 997px)":{
            fontWeight:"400",
            fontSize:"16px",
            lineHeight:"20px",
            left:"50%",
            bottom:"28px",
            width:"200px",
            marginLeft:"-205px",

        },
        "@media  only screen and (min-width:279px) and (max-width: 715px)":{
            fontWeight:"400",
            right:"0",
            width:"200px",
            bottom:"18px",
            marginLeft:"-205px",
            fontSize:10,
            lineHeight:"12px",
            left:"50%"
        }
    },

    bottomRow:{
        position:"absolute",
        width:"50%",
        left:"25%",
        height:35,
        bottom:0,
        textAlign:"center",
        lineHeight:"15px",
        paddingTop:5,
        "& $gameLevelBestInfo ,& $gameLevelTargetInfo":{
            display:"inline-block",
            position:"relative",
            margin:"auto",
            left:'auto',
            width:"auto",
            bottom:"auto",
            padding:"0px 5px",
            lineHeight:"15px",
        },

    }

});

const animationTime=500;

type AnimationItem={
    start:[number,number],
    end:[number,number],
    timer:number,
}

type currentLevelReducerAction=

    {pointIndex:number ,change?:number,set?:number }
// changeCurrentLevelData
// const   currentLevelReducer:React.Reducer<LevelType, currentLevelReducerAction[]>= (state, actions)=>{
const   changeCurrentLevelData:React.Reducer<LevelType, currentLevelReducerAction[]>= (state, actions)=>{

    let newValues= [...state.values];
    for(let act of actions){
        if(act.change){
            newValues[act.pointIndex] += act.change;
        }
        if(act.set){
            newValues[act.pointIndex]=act.set;
        }
   }

    return {...state,values:newValues}
    // return {count: state.count - 1};
}

type LogStepType=[ ("out"| 'in' ) , number ];

function isFinished(state:LevelType):boolean{
    let result=true;
    for (let v of state.values){
        if(v<0) {
            result = false;
            break;
        }
    }
    return result;
}


export const LevelPlay=(props:Props)=>{

    const gameContext=useContext(GameContext)!;
    const windowSize=useWindowSize();
    // console.log("windowSize",windowSize);
    const width=windowSize.width>640?640:windowSize.width;
    const classes=useStyles({width:width});
    const levelIndex=props.level-1;
    const navigate=useNavigate();
    const levelResult=gameContext!.results!.find(f=>f.level==props.level);
    const defaultLevelData=useMemo<LevelType >(()=>{
        return parseLevelData(gameContext!.levelsData[levelIndex],width,windowSize.height)
    },[windowSize]);


    // const [currentLevelData,changeCurrentLevelData]=React.useReducer(currentLevelReducer,defaultLevelData);
    const [currentLevelData,setCurrentLevelData]=React.useState(defaultLevelData);

    useEffect(()=>{
        let cl={...currentLevelData};
        cl.realPoints=defaultLevelData.realPoints;
        setCurrentLevelData(cl);
    },[defaultLevelData])

    const [logSteps,changeLogStep]=React.useState<LogStepType[]>([]);
    const [outListener,setOutListener]=useState(false);
    const [listenerIndex,setListenerIndex]=useState<number>(-1);
    const [revertCount,setRevertCount]=useState<number>(0);
    const [actionProgress,setActionProgress]=useState<boolean>(false);
    const [finished,setFinished]=useState<boolean>(false);
    const [animations,setAnimations]=useState<AnimationItem[]>([]);

    const  { t, i18n }= useTranslation();

    const [showAdCancelMove,setShowAdCancelMove]=useState(false);
    const [showAdRestart,setShowAdRestart]=useState(false);

    useEffect(()=>{
        if(gameContext.openedLevel<props.level){
            navigate("/")
         }


     },[]);

    useEffect(()=>{

        // Если уровень закончился
        if(isFinished(currentLevelData)){
            let result:LevelResultType={
                level:props.level,
                best:currentLevelData.best,
                cancels:revertCount,
                moves:logSteps.length
            }
            //записываем результаты
            gameContext.addUserResult(result);
            gameContext.setLastGameResult(result);
           //перекидываем на успешную страницу.
            setFinished(true);
          if([1,2,3].indexOf(props.level)==-1)navigate("/success"+props.level);
        }
    },[currentLevelData]);


    const addAnimation=(pointIndex:number,direct:('out'| 'in'),callback:()=>void)=>{
        let targets:number[]=[];
        let animations:AnimationItem[] =[];
        currentLevelData.links.map((points)=>{
            if(points[0]===pointIndex)targets.push(points[1]);
            if(points[1]===pointIndex)targets.push(points[0]);
        });
        let p1:[number,number];
        let p2:[number,number];
        targets.map((fromIndex)=> {
            p1 = [
                currentLevelData.realPoints![pointIndex].x,
                currentLevelData.realPoints![pointIndex].y,
            ];

            p2 = [
                currentLevelData.realPoints![fromIndex].x,
                currentLevelData.realPoints![fromIndex].y,
            ];

            if (direct === 'out') {
                animations.push({start:p1, end:p2, timer:animationTime});
            }
            if (direct === 'in') {
                animations.push({start:p2, end:p1, timer:animationTime});
            }

        });
        setAnimations(animations);
        setTimeout(callback,animationTime);
        setTimeout(()=>{setAnimations([])},animationTime);
    }

    const restartFn=()=>{
        setCurrentLevelData(defaultLevelData);
        changeLogStep([]);
    }
    const startOut=(index:number)=>{
        // console.log("start out");
        setOutListener(true);
        setListenerIndex(index);
    };
    const stopOut=()=>{
        if(outListener){
            // console.log("stop out");
            actionOut(listenerIndex);
            setOutListener(false);
        }
    };
    const canselOut=(index:number)=>{
        if(outListener && listenerIndex===index){
            // console.log("cansel out");
            setOutListener(false);
            setListenerIndex(-1);
        }else{
            // console.log("stop out");
            stopOut();
        }
    }
    const actionOut=(pointIndex:number,simple?:boolean,noLog?:boolean)=>{
        // console.log("actionOut");
        if(finished || actionProgress)return; //не запускать если уже идёт анимация.
        setActionProgress(true);
        if(!noLog){
            logSteps .push( ['out',pointIndex]);
            changeLogStep(logSteps)
        }


        let targets:number[]=[];
        // let newSate={...this.state};
        currentLevelData.links.map((points)=>{
            if(points[0]===pointIndex)targets.push(points[1]);
            if(points[1]===pointIndex)targets.push(points[0]);
        });
        let data=targets.map(t=> ({ change:1,pointIndex:t}));
        // без анимации нужно разом...
        // setCurrentLevelData(changeCurrentLevelData(currentLevelData,[{change:-1*targets.length,pointIndex:pointIndex},...data]));

        // //Вычитаем из текущей точки.
        let newState=changeCurrentLevelData(currentLevelData,[{change:-1*targets.length,pointIndex:pointIndex}]);
        setCurrentLevelData(newState);
        // //@TODO запускаем анимацию....
         addAnimation(pointIndex,'out',()=>{
             //после анимации добавляем в каждую цель по 1
             setCurrentLevelData(changeCurrentLevelData(newState,data));
             setActionProgress(false);
         });
        // //после анимации добавляем в каждую цель по 1
        //
        // changeCurrentLevelData(data);
    }


    const actionIn=(pointIndex:number,simple?:boolean,noLog?:boolean)=>{
        let targets:number[]=[];

        if(finished || actionProgress)return; //не запускать если уже идёт анимация.

        setActionProgress(true);
        if(!noLog){
            logSteps .push( ['in',pointIndex]);
            changeLogStep(logSteps)
        }
        currentLevelData.links.map((points)=>{
            if(points[0]===pointIndex)targets.push(points[1]);
            if(points[1]===pointIndex)targets.push(points[0]);
        });
        let data=targets.map(t=> ({ change:-1,pointIndex:t}));

        // без анимации
        // setCurrentLevelData(changeCurrentLevelData(currentLevelData,[{change:targets.length,pointIndex:pointIndex},...data]));

        //  анимация
        let newState=changeCurrentLevelData(currentLevelData,data);
        setCurrentLevelData(newState);
        addAnimation(pointIndex,'in',()=>{
            //после анимации добавляем в каждую цель по 1
            setCurrentLevelData(changeCurrentLevelData(newState,[{change:targets.length,pointIndex:pointIndex}]));
            setActionProgress(false);
        });



    }

    const canselMove=()=>{
        if(logSteps.length>0 && gameContext.revertMoveAvailable>0){
            const lastAction = logSteps.pop();
            if(!lastAction)throw new Error("Попытка отменить не сделанный ход");
            if(lastAction[0]==='in'){
                actionOut(lastAction[1],false,true);
            }
            if(lastAction[0]==='out'){
                actionIn(lastAction[1],false,true);
            }
            gameContext.setRevertMoves(gameContext.revertMoveAvailable-1);
        }else{
            if(gameContext.revertMoveAvailable===0){
                //реклама
                // setShowAdCancelMove(true);
                gameContext.setRevertMoves(3);
            }
        }
    }


    const onMouseDownPoint=(index:number)=>{
         startOut(index);
    }
    const onMouseUpPoint=(index:number)=>{
        canselOut(index);
    }
    const onDblClickPoint=(index:number)=>{
        actionIn(index);
    }
    const [waitForDoubleTouch,setWaitForDoubleTouch]=useState<boolean>(false);
    const onTouch=(pointIndex:number)=>{
        if(waitForDoubleTouch)actionIn(pointIndex);
        setWaitForDoubleTouch(true);
        setTimeout(()=>{ setWaitForDoubleTouch(false);},500);
    };

    const onTouchStartPoint=(index:number)=>{
         onTouch(index);
         startOut(index);
    }

    const onTouchEndPoint=(index:number)=>{
        canselOut(index);
    }
    const onTouchCanselPoint=(index:number)=>{
        startOut(index);
    }

    const helpImagePosition=useMemo(()=>{
        let x=width/2-150;
        let y=165;
              if(!currentLevelData!.realPoints)return {x,y};
        for(let p of  currentLevelData!.realPoints!){
            y=y>p.y?p.y:y;
        }

        y -=50;
        return {x,y};

    },[currentLevelData]);





    if(gameContext.openedLevel<props.level){
        // navigate("/")

        return null;
    }

    if(!currentLevelData || !currentLevelData!.realPoints)return null;
    return  (
        <div className={classes.mainWindow} >
            <Helmet>
                <title>{t('Уровень')}{` ${props.level} | `}{t('Головоломка Don\'t be negative')}</title>
            </Helmet>
            {showAdCancelMove && <OnGameAd close={()=>{
                gameContext.setRevertMoves(3);
                setShowAdCancelMove(false)
            }} />}
            {showAdRestart && <OnGameAd close={()=>{
                restartFn();
                setShowAdRestart(false)
            }} />}
            {/*<img src={Dot({color:"#df3331"}) as any}/>*/}
            <Stage
                //
                className={classes.canvas}
                onMouseUp={()=>{ stopOut(); }}
                onTouchEnd={()=>{ stopOut(); }}
                width={width}
                height={windowSize.height}
            >
                <GameContext.Provider value={gameContext}  >

                    <Layer  >
                        {currentLevelData.links.map((link,keyLink)=>(
                            currentLevelData.realPoints &&  <DrawLine key={keyLink}
                                x={currentLevelData.realPoints[link[0]].x}
                                y={currentLevelData.realPoints[link[0]].y}
                                x2={currentLevelData.realPoints[link[1]].x}
                                y2={currentLevelData.realPoints[link[1]].y}
                                color={"#000"}
                            />
                        ))}
                    </Layer>
                    <Layer >
                        {animations.map((a,index)=>(
                            <LineAnimation
                                key={index}
                                color={'#000000'}
                                start={a.start}
                                end={a.end }
                                timer={a.timer}
                                // timer={1000}
                            />
                        ))}

                    </Layer>
                        <Layer >
                            {currentLevelData!.realPoints!.map((point, index)=>
                                <Point color={currentLevelData?.color}
                                       key={index+String(currentLevelData!.values[index])}
                                       {...point}
                                       value={currentLevelData!.values[index]}
                                       onMouseDown={onMouseDownPoint}
                                       onMouseUp={onMouseUpPoint}
                                       onDblClick={onDblClickPoint}
                                       onTouchStart={onTouchStartPoint}
                                       onTouchEnd={onTouchEndPoint}
                                       onTouchCansel={onTouchCanselPoint}
                                       // pointRef={(r)=>{pointRefs.current[key]=r;}}
                                />
                            )}
                        </Layer>


                    <Layer>

                        {/*Показываем подсказки если таковые есть*/}
                        {/*{!finished && currentLevelData.text1 && <HelpText text={t(currentLevelData.text1)} />}*/}
                        {/*{finished && currentLevelData.text2 && <HelpText text={t(currentLevelData.text2)} />}*/}
                        {!finished && currentLevelData.text1 &&
                            <HelpImage
                                x={helpImagePosition.x} y={helpImagePosition.y}

                                onClick={()=>{}} textNumber={1} level={props.level} lng={i18n.language}   />}

                        {finished && currentLevelData.text2 &&
                            <HelpImage
                                x={helpImagePosition.x} y={helpImagePosition.y}

                                onClick={()=>{}}  textNumber={2} level={props.level} lng={i18n.language}   />}

                    </Layer>

                </GameContext.Provider>
            </Stage>

            <div className={classes.gameLevelInfo}>
                {t('уровень')} {props.level}
            </div>
            {!finished && <>
            <div className={classes.gameMoveCountInfo}>{logSteps.length} {t(LangSteps(logSteps.length))}</div>
                <div className={classes.bottomRow}>
            {levelResult && <div className={classes.gameLevelBestInfo}>{t('лучший')} &mdash; {levelResult.moves}</div>}
            <div className={classes.gameLevelTargetInfo}>{t('цель')} &mdash; {currentLevelData.best} </div>
                </div>
            </>}

            {/*Кнопка продолжить для учебных уровней.*/}
            {finished && currentLevelData.text2 &&  <div className={classes.lessonsGoNext}
                 onClick={()=>{
                    navigate("/level"+(props.level+1) );
                 }}
                 style={{background:currentLevelData.color}}
            >{t('Продолжить')}</div>}


            <SelectLevelButton  onClick={()=>{    navigate("/list" ); }}>{t("уровни")}</SelectLevelButton>
            <RestartButton onClick={()=>{
                //реклама
                // setShowAdRestart(true)
                restartFn();
            }}>{t('повторить')}</RestartButton>
            <RevertMove
                maxRevert={3}
                avbRevert={gameContext.revertMoveAvailable}
                onClick={()=>{canselMove()}}>{t('Отменить ход')}</RevertMove>


        </div>
    )


}

const HelpText=(props:{x?:number,y?:number,text:string})=>{

    return (
        <Text

            x={200} y={150}
              fontSize={14}
              text={props.text} />
    );
}

