import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {LevelPlay} from "./LevelPlay";
import {Logo} from "./Logo";
import {useContext, useEffect} from "react";
import {GameContext} from "../lib/GameContext";

export const LevelPlayPage:React.FC<{}>=( )=>{
    const params=useParams<{level:string}>();
    const gameContext=useContext(GameContext)!;
    const navigate=useNavigate();


    useEffect(()=>{
        if(parseInt(params.level  as string)>gameContext.levelsData.length){
            navigate("/list")
        }
    },[]);

    if(!params.level){
        throw new Error("Нужно указать уровень");
        return null;
    }
    return (<React.Fragment>
        <Logo />
        {parseInt(params.level)<=gameContext.levelsData.length &&
            <LevelPlay key={params.level} level={parseInt(params.level)} />}

    </React.Fragment>);
}