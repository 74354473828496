import * as React from 'react';

import Icon from './RevertMove/otmena.svg' ;
import {createUseStyles} from "react-jss";

const useStyles=createUseStyles<string,any>({
    Button:{
        backgroundImage: `url(${Icon})`,
        paddingLeft:"35px",
        position:"absolute",
        background:"transparent no-repeat 10px 2px",
        border:0,
        outline:0,
        cursor:"pointer",
        height:40,
        width:40,
        fontFamily:" 'Montserrat' , sans-serif",
        lineHeight:"15px",
        backgroundPosition:"left center",
        overflow:"hidden",
        left:"15px",
        bottom:"20px",
        fontStyle:"normal",
        fontWeight:"bold",
        fontSize:"12px",
        // textTransform:"capitalize",
        "&&:after":{
            content:({max,avb}:{max:number,avb:number})=>"'"+`${avb}/${max}`+"'",
            position:"absolute",
           textAlign:"center",
            width:38,
            height:38,
            top:0,
            fontSize:14,
            left:4
        },

        "@media  only screen and (min-width: 997px)":{
            paddingLeft: 52,
            lineHeight: "38px",
            right: 46,
            bottom:42,
            fontStyle:"normal",
            fontWeight:"700",
            fontSize:"20px",
            width:"auto",
            left:"auto",
        },
        "@media  only screen and (min-width:279px) and (max-width: 997px)":{
            padding:"0",
            left:"auto",
            bottom:"20px",
            right:"15px",
            width:"45px",
            color:"transparent",
            "&&:after":{
                color:"#000",
                content:({max,avb}:{max:number,avb:number})=>"'"+`${avb}/${max}`+"'",
                position:"absolute",
                textAlign:"center",
                width:38,
                height:38,
                fontSize:12,
                lineHeight:"38px",
            },
        }
    }
});
type Props=
    Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> &
    { maxRevert:number,avbRevert:number}

export const RevertMove:React.FC< Props>=({className,maxRevert,avbRevert,...props})=>{

    const classes=useStyles({max:maxRevert,avb:avbRevert});
    const innerStyle={backgroundImage: `url(${Icon})`};
    return (
        <button
            {...props}
            className={className?className+" "+classes.Button:classes.Button}
        />
    );
}