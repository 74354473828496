import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Circle, Layer} from "react-konva";


const Conf={
    size:4,
    timer:1,

}
type LineAnimationProps={
    start:[number,number],
    end:[number,number],
    timer:number,
    color:string,
    destroy?:()=>{}
}
  const LineAnimation= (props:LineAnimationProps)=>{
    const [currentPos,setCurrentPos]=useState({x:props.start[0],y:props.start[1]});


    const stepDiff= [
             (props.end[0]-props.start[0])/(60),
             (props.end[1]-props.start[1])/(60)
        ];

    useEffect(()=>{
        let newCurrent:typeof currentPos= {...currentPos};
        let counter=60;
       let _timer=setInterval(()=> {
            newCurrent.x=newCurrent.x+stepDiff[0];
            newCurrent.y= newCurrent.y+stepDiff[1];

           setCurrentPos({...newCurrent});
            counter--;
            if(counter<=0){
                if(_timer)clearInterval(_timer);
                if(props.destroy)props.destroy();
            }
        },props.timer/60);
        return ()=>{
            if(_timer)clearInterval(_timer);
        }
    },[]);

   return (
       <>
           <Circle  x={currentPos.x}
                   y={currentPos.y}
                   radius={Conf.size}
                   fill={props.color}
           /></>

   );
}

export default LineAnimation;